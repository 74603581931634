import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ROUTES from "../../routes";
import Breadcrumb from "../../Common/Breadcrumb";
import { Carousel } from "react-bootstrap";
import { geturl, trimTextToLength } from "../../Utils/helper";
import ReadOnlyProduct from "../ReadOnlyProduct";
import LoadImage from "../../Common/LoadImage";
import { Helmet } from "react-helmet-async";
import { slugify } from "../../Utils/helper";

const ReadOnlyCategory: FC = () => {
  const [categoryData, setCategoryData] = useState<any>("");
  const { state } = useLocation();
  const [metaTitle, setMetaTitle] = useState("Readonly Category | SkyAzul");
  const [ogUrl, setOgUrl] = useState<string>("");
  const [metaDescription, setMetaDescription] = useState("Readonly Category description");

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getReadOnlyCategory() {
      if (state?.category && state?.category?.description) {
        let longDec = state?.category.description;
        const el = document.createElement("div");
        el.innerHTML = longDec;

        el.querySelectorAll("img").forEach((imgEl) => {
          const srcAtt = imgEl.getAttribute("src");
          if (srcAtt) {
            const srcOriginal = geturl(srcAtt);
            imgEl.src = srcOriginal;
          }
        });
        longDec = el.innerHTML;
        const category = state?.category;
        const slugifiedName = slugify(category?.name);
        setMetaTitle(`${category?.name} | SkyAzul`);
        setOgUrl(`${window.location.origin}/readonly-categories/${category?.id}/${slugifiedName}`);
        const descriptionText = category?.description
          ? category?.description.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ').trim()
          : "";
          const trimmedDescription = trimTextToLength(descriptionText);
          setMetaDescription(trimmedDescription || "Product details page description");
        setCategoryData({ ...category, description: longDec });
      }
    }
    getReadOnlyCategory();
  }, [state]);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: categoryData?.name,
      path: "#",
    },
  ];      
  

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={ogUrl} />
      </Helmet>
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle={categoryData?.name} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <h4 className="block-title-xl">{categoryData?.name}</h4>
            <div className="hr--divider"></div>
          </div>
        </div>
        <div className="row mt-3">
          {categoryData?.images?.length > 0 && (
            <div className="col-12 mb-3">
              <Carousel className="banner-slider product-img-preview-slider">
                {categoryData?.images?.map((dataItem: any, index: number) => {
                  return (
                    <Carousel.Item key={index}>
                      <LoadImage src={dataItem?.url} inlineClass="img-fluid" />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
          <div className="col-12 mb-3 mt-3">
            <p
              className="cmn-txt img-width"
              dangerouslySetInnerHTML={{
                __html: categoryData?.description,
              }}
            ></p>
          </div>
          <ReadOnlyProduct categoryId={categoryData?.id} />
        </div>
      </div>
    </>
  );
};

export default ReadOnlyCategory;
