/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import CommonService from "../../API/Common";
import PdfListing from "../../Common/PdfListing";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import Loader from "../../Common/Loader";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { PAGESIZE, VALIDATIONS } from "../../Utils/constants";
import { Helmet } from "react-helmet-async";

const Resource: FC = () => {
  const [pdfData, setPdfData] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [pdfPage, setPdfPage] = useState(1);
  const [pdfTotal, setPdfTotal] = useState(0);
  const [showLoadMorePdf, setShowLoadMorePdf] = useState<boolean>(false);
  const [showMoreSelectedPdf, setShowMoreSelectedPdf] = useState<boolean>(false);
  const [metaTitle, setMetaTitle] = useState("Resources | SkyAzul");

  const errorBlock = (err: any) => {
    setLoader(false);
    NotificationWithIcon(
      "error",
      err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
    );
  };

  const getPdfTutorials = async (page: number) => {
    setLoader(true);
    await CommonService.getResources(
      `start=${page}&limit=${PAGESIZE}&tutorial=PDF`
    )
      .then((res: any) => {
        if (res && res?.status === "Success") {
          const arr = res?.data;
          const pdfArr = arr?.map((item: any) => {
            return {
              id: item?.PDF?.id,
              pdfTitle: item?.PDF?.Title,
              pdf: geturl(item?.PDF?.PDF?.url),
            };
          });

          let pdfArray: any = [];
          if (showMoreSelectedPdf) {
            pdfArray = [...pdfData, ...pdfArr];
          } else {
            setPdfData([]);
            pdfArray = [...pdfArr];
          }
          setPdfData(pdfArray);
          setShowMoreSelectedPdf(false);
          setPdfTotal(res?.meta?.total);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        errorBlock(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getTutorialsDetails() {
      await getPdfTutorials(pdfPage);
    }
    getTutorialsDetails();
  }, []);

  useEffect(() => {
    if (pdfPage > 1) {
      getPdfTutorials(pdfPage);
    }
  }, [pdfPage]);

  useEffect(() => {
    if (pdfData?.length < pdfTotal) {
      setShowLoadMorePdf(true);
    }
    if (pdfData?.length === pdfTotal) {
      setShowLoadMorePdf(false);
    }
  }, [pdfData]);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Resources",
      path: "#",
    },
  ];

  const onViewMore = () => {
    setPdfPage((p) => p + 1);
    setShowMoreSelectedPdf(true);
  };

  useEffect(() => {
    setMetaTitle("Resources | SkyAzul"); 
  }, []);


  return (
    <>
        <Helmet>
     <title>{metaTitle}</title>
     <meta property="og:title" content="Resources | SkyAzul" />
     <meta
          property="og:description"
          content="Resource landing page."
        />
        <meta
          property="og:url"
          content={`${window.location.origin}${ROUTES.RESOURCES}`}
        />
     </Helmet>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Resources" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <div className="container pb-5">
              <div className="row mt-3">
                <div className="col-12 p-0">
                  <div className="list--box tutorial">
                    <ul className="list-unstyled doc--list">
                      {pdfData.length > 0 && <PdfListing pdfs={pdfData} />}
                    </ul>
                  </div>
                  {pdfData.length === 0 && (
                    <div>
                      <h3>No data found</h3>
                    </div>
                  )}
                </div>
                {showLoadMorePdf && (
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      className="btn btn-borderd--primary"
                      onClick={onViewMore}
                    >
                      View More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resource;
