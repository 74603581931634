/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row, Select } from "antd";
import { Field, Formik, FormikValues } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import CommonService from "../../API/Common";
import {
  filterData,
  getCountries,
  getErrorMessage,
  NotificationWithIcon,
  validateContact,
  validateZipcode,
} from "../../Utils/helper";
import { quoteValidations } from "../../Utils/validations";
import * as Constants from "../../Utils/constants";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import ProductService from "../../API/Product";
import Loader from "../../Common/Loader";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

const RequestQuote: FC = () => {
  const [countriesData, setCountriesData] = useState<any>([]);
  const [statesData, setStatesData] = useState<any>([]);
  const [cityData, setCityData] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [products, setProducts] = useState<any>([]);
  const [selected, setSelected] = useState([]);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [cityTotal, setCityTotal] = useState<any>(null);
  const [page, setPage] = useState<any>(1);
  const [metaTitle, setMetaTitle] = useState("Request Quote | SkyAzul");
  const authState = useSelector((state: any) => state.auth);
  const { userInfo } = authState;
  const defaultCountry: any = Constants.DEFAULT_COUNTRY;
  const url = "all=true&sort=id:asc";

  const getCountry = async () => {
    await getCountries(setCountriesData);
  };

  const getStates = async (countryId: string) => {
    await CommonService.getState(countryId, url)
      .then((res: any) => {
        if (res && res?.data) {
          setStatesData(res?.data);
        }
      })
      .catch(() => {
        setStatesData([]);
      });
  };

  const getCity = async (
    pageNumber: number,
    stateId: string,
    search?: string
  ) => {
    let cityUrl = `pagination[page]=${pageNumber}&pagination[pageSize]=50&sort=id:asc`;
    if (search) {
      cityUrl += `&search=${search}`;
    }
    await CommonService.getCity(stateId, cityUrl)
      .then((res: any) => {
        let arr: any = [];
        if (search || pageNumber === 1) {
          setCityData([]);
          arr = [...res?.data];
        } else {
          arr = [...cityData, ...res?.data];
        }
        setCityData(arr);
        if (!cityTotal) {
          setCityTotal(res?.meta?.total);
        }
      })
      .catch(() => {
        setCityData([]);
      });
  };

  const onCountrySelect = (country: string) => {
    getStates(country);
  };

  const onStateSelect = (state: string) => {
    setSelectedState(state);
    setPage(1);
    getCity(1, state);
  };

  const getProducts = async () => {
    const queryParams = `all=true`;
    await ProductService.getAllReadOnlyProducts(queryParams)
      .then((res: any) => {
        setProducts(
          res?.data.map((d: any) => ({
            value: d?.id,
            label: d?.name,
          }))
        );
      })
      .catch((er: any) => {
        NotificationWithIcon(
          "error",
          er?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getData() {
      getCountry();
      getStates(userInfo?.country ? userInfo?.country?.id : defaultCountry.id);
      getProducts();
    }
    getData();
  }, []);

  const clearForm = (resetForm: any, setFieldValue: any) => {
    setFieldValue("country", "");
    setFieldValue("state", "");
    setFieldValue("city", "");
    setFieldValue("interestedIn", []);
    resetForm();
  };

  const onSubmitRequest = async (
    values: FormikValues,
    resetForm: any,
    setFieldValue: any
  ) => {
    setLoader(true);
    const reqObj = {
      data: {
        FirstName: values.firstname.trim(),
        LastName: values.lastname.trim(),
        address: values.address.trim(),
        city: values.city,
        zip: values.zipcode,
        state: values.state,
        country: values.country,
        company: values.company.trim(),
        email: values.email,
        phone: values.phone,
        fax: values.fax,
        interestedIn: values.interested,
        description: values.description.trim(),
      },
    };
    await ProductService.sendRequestForQuote(reqObj)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", res?.message);
          clearForm(resetForm, setFieldValue);
          setSelected([]);
        }
      })
      .catch((error: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          error?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Request A Quote",
      path: "#",
    },
  ];

  const handleSelectOption = (e: any, setFieldValue: any) => {
    const sel = products.filter((item: any) => {
      return e.some((selectedValues: any) => {
        return selectedValues === item.value;
      });
    });
    setFieldValue("interested", e);
    setSelected(sel);
  };

  const handleRemoveValue = (e: any, setFieldValue: any) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = selected.find(
      (val: any) => val?.value === Number(buttonName)
    );
    if (!removedValue) return;
    const removed = selected.filter(
      (val: any) => val.value !== Number(buttonName)
    );
    handleSelectOption(
      removed.map((item: any) => item.value),
      setFieldValue
    );
  };

  const onSearchCity = (searchValue: string, setFieldValue: any) => {
    setPage(1);
    setFieldValue("city", searchValue);
    if (searchValue !== "") {
      getCity(1, selectedState, searchValue);
    } else {
      getCity(1, selectedState);
    }
  };

  const onCityScroll = async (e: any) => {
    if (cityTotal === cityData?.length) {
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPage((page: any) => page + 1);
      await getCity(page + 1, selectedState);
    }
  };
  useEffect(() => {
    setMetaTitle("Request Quote | SkyAzul"); 
  }, []);

  return (
    <>
        <Helmet>
     <title>{metaTitle}</title>
     <meta property="og:title" content="Request Quotes | SkyAzul" />
     <meta
          property="og:description"
          content="Page that customers can request a quote for our products at SkyAzul"
        />
        <meta
          property="og:url"
          content={`${window.location.origin}${ROUTES.REQUEST_QUOTE}`}
        />
     </Helmet>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Request A Quote" />
      <div className="container">
        <Container fluid>
          <Formik
            initialValues={{
              firstname: userInfo?.username?.split(" ")?.[0] || "",
              lastname: userInfo?.username?.split(" ")?.[1] || "",
              address: "",
              country: userInfo?.country?.id || defaultCountry?.id,
              state: "",
              city: "",
              zipcode: "",
              company: "",
              email: userInfo?.email || "",
              phone: userInfo?.contactNumber || "",
              fax: "",
              interested: [],
              description: "",
            }}
            onSubmit={(values, { resetForm, setFieldValue }) => {
              onSubmitRequest(values, resetForm, setFieldValue);
            }}
            validationSchema={quoteValidations}
            validateOnBlur={false}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form layout="vertical" onSubmitCapture={handleSubmit}>
                <Row gutter={16}>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item
                      label="First name*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="firstname"
                        name="firstname"
                        placeholder="Enter first name"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("firstname")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item label="Last name*" className="custom-field mb-3">
                      <Field
                        id="lastname"
                        name="lastname"
                        placeholder="Enter last name"
                        className="form-control custom-form-control"
                      />{" "}
                      {getErrorMessage("lastname")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-12 col-12" xs={24}>
                    <Form.Item label="Address*" className="custom-field mb-3">
                      <Field
                        id="address"
                        name="address"
                        placeholder="Line 1,2"
                        className="form-control custom-form-control"
                        maxLength={Constants.MAX_STREET_ADDRESS_LENGTH}
                      />
                      {getErrorMessage("address")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item label="Country*" className="custom-field mb-3">
                      <Select
                        popupClassName="custom--select"
                        placeholder="--Select--"
                        options={countriesData?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        showSearch
                        onChange={(e) => {
                          setFieldValue("country", e);
                          onCountrySelect(e);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setCityData([]);
                        }}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        value={
                          values.country === "" ? undefined : values.country
                        }
                      />
                      {getErrorMessage("country")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item
                      label="State/Province*"
                      className="custom-field mb-3"
                    >
                      <Select
                        showSearch
                        popupClassName="custom--select"
                        placeholder="--Select--"
                        options={statesData?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        onChange={(e) => {
                          onStateSelect(e);
                          setFieldValue("state", e);
                          setFieldValue("city", "");
                        }}
                        value={values.state === "" ? undefined : values.state}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                      />
                      {getErrorMessage("state")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item label="City*" className="custom-field mb-3">
                      <Select
                        showSearch
                        popupClassName="custom--select"
                        placeholder="--Select--"
                        options={cityData?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        onChange={(e) => {
                          setFieldValue("city", e);
                        }}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        value={values.city === "" ? undefined : values.city}
                        onSearch={(e: any) => onSearchCity(e, setFieldValue)}
                        onPopupScroll={onCityScroll}
                      />
                      {getErrorMessage("city")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item label="Zip*" className="custom-field mb-3">
                      <Field
                        id="zipcode"
                        name="zipcode"
                        className="form-control custom-form-control"
                        placeholder="Enter zip code"
                        validate={validateZipcode}
                      />
                      {getErrorMessage("zipcode")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item label="Company*" className="custom-field mb-3">
                      <Field
                        id="company"
                        name="company"
                        className="form-control custom-form-control"
                        placeholder="Enter your company name"
                      />
                      {getErrorMessage("company")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    {" "}
                    <Form.Item label="Email*" className="custom-field mb-3">
                      <Field
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        className="form-control custom-form-control"
                      />{" "}
                      {getErrorMessage("email")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item
                      label="Contact number*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="phone"
                        name="phone"
                        validate={validateContact}
                        render={(renderProps: any) => (
                          <input
                            {...renderProps.field}
                            type="text"
                            placeholder="Enter your number"
                            maxLength={Constants.CONTACT_LENGTH}
                            className="form-control custom-form-control"
                          />
                        )}
                      />{" "}
                      {getErrorMessage("phone")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item label="Fax*" className="custom-field mb-3">
                      <Field
                        id="fax"
                        name="fax"
                        placeholder="Enter your fax"
                        className="form-control custom-form-control"
                      />{" "}
                      {getErrorMessage("fax")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12" >
                    <Form.Item
                      label="I am interested In*"
                      className="custom-field mb-3"
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        popupClassName="custom--select"
                        className="multiple-multiple-item-select"
                        placeholder="--Select--"
                        options={products}
                        onChange={(e) => {
                          handleSelectOption(e, setFieldValue);
                        }}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        value={values.interested}
                      />
                      {selected.length > 0 && (
                        <ul className="list-unstyled d-flex flex-wrap align-items-center interest-list">
                          {selected.map((item: any) => (
                            <li>
                              {item?.label}
                              <button
                                name={item?.value}
                                onClick={(e: any) =>
                                  handleRemoveValue(e, setFieldValue)
                                }
                              >
                                ✕
                              </button>{" "}
                            </li>
                          ))}
                        </ul>
                      )}
                      {getErrorMessage("interested")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-12 col-12" xs={24}>
                    <Form.Item
                      label="Describe your Application"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="description"
                        name="description"
                        render={(renderProps: any) => (
                          <textarea
                            {...renderProps.field}
                            type="text"
                            placeholder="Enter your Message here...."
                            className="form-control custom-form-control"
                            rows={6}
                          />
                        )}
                      />{" "}
                      {getErrorMessage("description")}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="mt-3 submit-btn">
                  <Button
                    type="submit"
                    className="w-100 btn--primary btn w-200"
                  >
                    SUBMIT
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </>
  );
};

export default RequestQuote;
