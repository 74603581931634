import { FC, useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import { Field, Formik } from "formik";
import { Button, Container } from "react-bootstrap";
import { getErrorMessage, NotificationWithIcon } from "../../Utils/helper";
import { contactValidation } from "../../Utils/validations";
import CommonService from "../../API/Common";
import { VALIDATIONS, GOOGLE_MAP_URL } from "../../Utils/constants";
import Loader from "../../Common/Loader";
import ROUTES from "../../routes";
import Breadcrumb from "../../Common/Breadcrumb";
import { Helmet } from "react-helmet-async";

const ContactUs: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [metaTitle, setMetaTitle] = useState("Contact Us | SkyAzul");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSend = async (
    values: {
      name: string;
      email: string;
      subject: string;
      message: string;
    },
    resetForm: any
  ) => {
    setLoader(true);
    const data: Object = {
      name: values.name.trim(),
      email: values.email,
      subject: values.subject.trim(),
      message: values.message.trim(),
    };
    await CommonService.sendContactDetails(data)
      .then((res: any) => {
        if (res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", VALIDATIONS.CONTACT_SUCCESS);
          resetForm();
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Contact Us",
      path: "#",
    },
  ];

  useEffect(() => {
    setMetaTitle("Contact Us | SkyAzul"); 
  }, []);


  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content="Contact Us | SkyAzul" />
        <meta
          property="og:description"
          content="Page that provides SkyAzul office’s location. Our team of experts is on hand to answer your questions."
        />
        <meta
          property="og:url"
          content={`${window.location.origin}${ROUTES.CONTACT}`}
        />
      </Helmet>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Contact Us" />
      <div className="container map-block mt-2">
        <iframe
          src={GOOGLE_MAP_URL}
          width={"100%"}
          height="550"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
        <address className="address-block">
          <label>SkyAzul</label>
          <p className="mb-2">16 Walnut Street, Middletown, Maryland, 21769</p>
          <p>Phone: (301) 371-6126</p>
          <p>Fax: (301) 371-0029 </p>
          <p>info@skyazul.com</p>
        </address>
      </div>
      <div className="online-store-block">
        <div className="container position-relative">
          <div className="row mb-4">
            <div className="col-md-12 text-center">
              <h4 className="block-title-xxl text-dark mb-0">Contact Us</h4>
              <p className="cmn-txt cmn-txt-md mb-0">
                We can help. Our team of experts is on hand to answer your
                questions.
              </p>
            </div>
          </div>
          <Container className="fixed-width-container">
            <Formik
              initialValues={{
                name: "",
                email: "",
                subject: "",
                message: "",
              }}
              onSubmit={(values: any, { resetForm }) => {
                onSend(values, resetForm);
              }}
              validationSchema={contactValidation}
              validateOnBlur={false}
            >
              {({ handleSubmit }) => (
                <Form
                  layout="vertical"
                  form={form}
                  onSubmitCapture={handleSubmit}
                >
                  <Row gutter={16}>
                    <Col className="col-lg-6 col-12">
                      <Form.Item
                        label="First name*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="name"
                          name="name"
                          placeholder="Enter your name"
                          className="form-control custom-form-control"
                        />
                        {getErrorMessage("name")}
                      </Form.Item>
                    </Col>
                    <Col className="col-lg-6 col-12">
                      <Form.Item
                        label="Email address*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          className="form-control custom-form-control"
                        />{" "}
                        {getErrorMessage("email")}
                      </Form.Item>
                    </Col>
                    <Col className="col-lg-12 col-12">
                      <Form.Item label="Subject*" className="custom-field mb-3">
                        <Field
                          id="subject"
                          name="subject"
                          placeholder="Enter your subject"
                          className="form-control custom-form-control"
                        />{" "}
                        {getErrorMessage("subject")}
                      </Form.Item>
                    </Col> 
                    <Col className="col-lg-12 col-12">
                      <Form.Item label="Message*" className="custom-field mb-2">
                        <Field
                          id="message"
                          name="message"
                          placeholder="Enter your Message here...."
                          className="form-control custom-form-control"
                          component="textarea"
                          rows={5}
                        />{" "}
                        {getErrorMessage("message")}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item className="mt-3 text-center">
                    <Button
                      type="submit"
                      className="w-100 btn--primary btn w-200"
                    >
                      SEND
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
