/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductService from "../../API/Product";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { geturl, NotificationWithIcon, trimTextToLength } from "../../Utils/helper";
import Loader from "../../Common/Loader";
import { Document, pdfjs, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import LoadImage from "../../Common/LoadImage";
import { Helmet } from "react-helmet-async";
import { slugify } from "../../Utils/helper";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReadOnlyProductDetails: FC = () => {
  const [productDetail, setProductDetails] = useState<any>();
  const [loader, setLoader] = useState<any>(false);
  const [, setNumPages] = useState<any>({ lit: null, manual: null });
  const [metaTitle, setMetaTitle] = useState("Readonly Products Details | SkyAzul");
  const [ogUrl, setOgUrl] = useState<string>("");
  const [metaDescription, setMetaDescription] = useState("Product details page description");
  const [pageNumber] = useState<any>({ lit: 1, manual: 1 });
  const { id } = useParams();

  const getProductDetail = async () => {
    setLoader(true);
    const queryParams =
      "populate[images][fields]=url&populate[category]=*&populate[productLiterature][fields]=url,name&populate[manualsServiceDocumentation][fields]=url,name";
    await ProductService.getProductDetail(Number(id), queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          let response = res?.data;
          let el = document.createElement("div");
          el.innerHTML = response?.description;

          el.querySelectorAll("img").forEach((imgEl) => {
            const srcAtt = imgEl.getAttribute("src");
            if (srcAtt) {
              const srcOriginal = geturl(srcAtt);
              imgEl.src = srcOriginal;
            }
          });
          setMetaTitle(`${response?.name} | SkyAzul`);
          const slugifiedName = slugify(response?.name);
          setOgUrl(`${window.location.origin}/readonly-category/${id}/${slugifiedName}`);
          const descriptionText = response?.description ? response?.description.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ').trim() : "";
          const trimmedDescription = trimTextToLength(descriptionText);
          setMetaDescription(trimmedDescription || "Product details page description");
          setProductDetails({ ...response, description: el.innerHTML });
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon("error", err?.data?.error?.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getProduct() {
      await getProductDetail();
    }
    getProduct();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Product Details",
      path: "#",
    },
  ];

  const onDocumentLoadSuccess = (pages: any, type: string) => {
    setNumPages({ ...pages, [type]: pages });
  };



  return (
    <>
    <Helmet>
    <title>{metaTitle}</title>
     <meta property="og:title" content={metaTitle} />
     <meta
          property="og:description"
          content={metaDescription}
        />
        <meta property="og:url" content={ogUrl} />
        <link rel="canonical" href={ogUrl} />
    </Helmet>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Product Details" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="block-title-xl">{productDetail?.name}</h4>
            <div className="hr--divider"></div>
          </div>
        </div>
      </div>
      <div className="container pb-5 d-flex align-items-start productdetail">
        <div className="readonly-product-detail-block w-100">
          <div className="row mt-3">
            <div className="col-12 mb-4">
              <p
                className="cmn-txt"
                dangerouslySetInnerHTML={{
                  __html: productDetail?.description,
                }}
              ></p>
            </div>
            <div className="col-12 mb-3 d-flex justify-content-center">
              <LoadImage
                src={productDetail?.images?.url}
                inlineClass="img-fluid max-height-400"
              />
            </div>
          </div>
        </div>
        <div className="readonly-product-doc-block mt-3">
          {productDetail?.productLiterature && (
            <div>
              <h3>Product Literature</h3>
              <div className="readonly-product-box">
                <Document
                  file={geturl(productDetail?.productLiterature?.url)}
                  onLoadSuccess={({ numPages }) =>
                    onDocumentLoadSuccess(numPages, "lit")
                  }
                  renderMode="svg"
                >
                  <Page pageNumber={pageNumber.lit} />
                </Document>
                <p className="product--name">
                  <a
                    href={geturl(productDetail?.productLiterature?.url)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {productDetail?.productLiterature?.name?.split(".")[0]}
                  </a>
                </p>
              </div>
            </div>
          )}
          {productDetail?.manualsServiceDocumentation && (
            <div>
              <h3>Manuals & service documentation</h3>
              <div className="readonly-product-box">
                <Document
                  file={geturl(productDetail?.manualsServiceDocumentation?.url)}
                  onLoadSuccess={({ numPages }) =>
                    onDocumentLoadSuccess(numPages, "manual")
                  }
                  renderMode="svg"
                >
                  <Page pageNumber={pageNumber.manual} />
                </Document>
                <p className="product--name">
                  <a
                    href={geturl(
                      productDetail?.manualsServiceDocumentation?.url
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {
                      productDetail?.manualsServiceDocumentation?.name?.split(
                        "."
                      )[0]
                    }
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReadOnlyProductDetails;
