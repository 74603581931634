import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import ROUTES from "../../routes";
import * as Constants from "../../Utils/constants";
import CommonService from "../../API/Common";
import Breadcrumb from "../../Common/Breadcrumb";
import { Helmet } from "react-helmet-async";  

const Aboutus: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [aboutData, setAboutData] = useState<any>(null);
  const [page] = useState<number>(1);
  const [isActive, setActive] = useState<any>({});
  const [metaTitle, setMetaTitle] = useState("About Us | SkyAzul");

  const handleText = (id: number) => {
    setActive({ ...isActive, [id]: !isActive?.[id] });
  };

  const getAboutus = async () => {
    setLoader(true);
    const queryParams = `populate=*&pagination[page]=${page}&pagination[pageSize]=${Constants.PAGESIZE}`;
    await CommonService.getAboutus(queryParams)
      .then((res: any) => { 
        if (res && res?.data) {
          const aboutAttributes = res.data.attributes;    
          if (aboutAttributes) {
            const newData = [
              {
                description: aboutAttributes.description,
                id: res.data.id,
              },
            ];

            newData.forEach((i: { description: string }) => {
              let el = document.createElement("div");
              el.innerHTML = i.description;
              el.querySelectorAll("img").forEach((imgEl) => {
                const srcAtt = imgEl.getAttribute("src");
                if (srcAtt) {
                  const srcOriginal = geturl(srcAtt);
                  imgEl.src = srcOriginal;
                }
              });
              i.description = el.innerHTML;
            });
            setAboutData(newData);
          }
          setLoader(false);
        } else {
          throw new Error("No data found");
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getAboutDetails() {
      await getAboutus();
    }
    getAboutDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "About-us",
      path: "#",
    },
  ];

  useEffect(() => {
    setMetaTitle("About Us | SkyAzul"); 
  }, []);

  return (
    <>
     <Helmet>
     <title>{metaTitle}</title>
     <meta property="og:title" content="About Us | SkyAzul" />
     <meta
          property="og:description"
          content="SkyAzúl Equipment Solutions is a proven leader in the sales and service of monitoring systems for construction and mining equipment."
        />
      <meta
          property="og:url"
          content={`${window.location.origin}${ROUTES.ABOUT_US}`}
        />
     </Helmet>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="About-us" />
      {aboutData && (
        <div className="container">
          <ul className="list-unstyled news--list">
            {aboutData.map((item: { id: number; description: string }) => {
              const desc = isActive?.[item.id]
                ? item.description
                : `${item.description.slice(
                    0,
                  )}...`;
              return (
                <li key={item.id} className="news-list">
                  <span
                    className="notranslate"
                    dangerouslySetInnerHTML={{
                      __html: desc,
                    }}
                  ></span>
                  {item?.description?.length >
                    Constants.READ_MORE_CHAR_SIZE && (
                    <Link
                      to="#"
                      onClick={() => handleText(item.id)}
                      className="text--primary cmn-txt font-weight-medium"
                    >
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Aboutus;