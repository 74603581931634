/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import * as Constants from "../../Utils/constants";
import CommonService from "../../API/Common";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import ROUTES from "../../routes";
import Breadcrumb from "../../Common/Breadcrumb";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const FAQs: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [faqsData, setFaqsData] = useState<any>([]);
  const [page] = useState<number>(1);
  const [metaTitle, setMetaTitle] = useState("FAQ's | SkyAzul")

  const getFaqs = async () => {
    setLoader(true);
    const queryParams = `populate=*&pagination[page]=${page}&pagination[pageSize]=${Constants.PAGESIZE}`;
    await CommonService.getFaqs(queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          const arr = res?.data.map((i: any) => {
            const el = document.createElement("div");
            el.innerHTML = i.answer;
            el.querySelectorAll("img").forEach((imgEl) => {
              const srcAtt = imgEl.getAttribute("src");
              if (srcAtt) {
                const srcOriginal = geturl(srcAtt);
                imgEl.src = srcOriginal;
              }
            });
            i.answer = el.innerHTML;
            return i;
          });
          setFaqsData(arr);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getFaqsDetails() {
      await getFaqs();
    }
    getFaqsDetails();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "FAQs",
      path: "#",
    },
  ];

  useEffect(() => {
    setMetaTitle("FAQ's | SkyAzul"); 
  }, []);


  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content="FAQ | SkyAzul" />
        <meta
          property="og:description"
          content="Page dedicated to frequently asked questions that relate to our services"
        />
        <meta
          property="og:url"
          content={`${window.location.origin}${ROUTES.FAQ}`}
        />
      </Helmet>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="FAQs" />
      <div className="container pb-5">
        <div className="row mt-3">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0" className="custom-accordion">
              {faqsData?.length > 0 &&
                faqsData.map((dataItem: any) => (
                  <Accordion.Item eventKey={dataItem?.id}>
                    <Accordion.Header>{dataItem?.question}</Accordion.Header>
                    <Accordion.Body className="p-0">
                      <div
                        className="custom-part-number-table"
                        dangerouslySetInnerHTML={{
                          __html: dataItem?.answer,
                        }}
                      ></div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
